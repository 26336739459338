/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
  direction: rtl;
  /* Set text direction to right-to-left */
  text-align: right;
  /* Align text to the right */
}
.warning-message {
  color: #856404; /* Friendly dark orange tone */
  background-color: #fff3cd; /* Light yellow background */
  border: 1px solid #ffeeba; /* Soft yellow border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 15px; /* Comfortable padding */
  font-size: 1rem; /* Readable font size */
  font-weight: 500; /* Moderate boldness */
  text-align: center; /* Center-align the text */
  margin: 15px 0; /* Add spacing around the element */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for aesthetics */
}

.ambassador-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Welcome Banner */
.welcome-banner {
  background-color: #28527a;
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.welcome-banner h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.welcome-banner p {
  font-size: 1.2rem;
}

/* Section Titles */
.section-title {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #28527a;
  border-bottom: 2px solid #28527a;
  display: inline-block;
}

/* Dashboard Overview */
.dashboard-overview .dashboard-stats {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.stat-card {
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  flex: 1;
}

.stat-card h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #555;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

/* Discount Code Section */
.discount-code-info {
  text-align: center;
  margin-bottom: 30px;
}

.discount-code {
  font-size: 1.5rem;
  color: #28527a;
}

.copy-button {
  background-color: #28527a;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.copy-button:hover {
  background-color: #3e8f41;
}

/* Sharing Tools */
.sharing-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
}

.share-button {
  background-color: #28527a;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.share-button:hover {
  background-color: #3e8f41;
}

/* Earnings Breakdown */
.breakdown-list {
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
}

.breakdown-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.breakdown-item span {
  font-size: 1rem;
}

/* Payout Options */
.payout-info {
  margin-bottom: 20px;
}

.payout-history {
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
}

.payout-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.payout-item span {
  font-size: 1rem;
}

/* Marketing Resources */
.resources-content {
  text-align: center;
  margin-bottom: 30px;
}

.download-button {
  background-color: #28527a;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.download-button:hover {
  background-color: #3e8f41;
}

/* Terms and Conditions */
.terms-content {
  text-align: center;
}

.view-terms-button {
  background-color: #28527a;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.view-terms-button:hover {
  background-color: #3e8f41;
}

/* Footer Styles */
.footer {
  background-color: #f8f8f8;
  padding: 20px 10px;
  border-top: 2px solid #28527a;
  text-align: center;
  margin-top: 40px;
}

.footer-support {
  max-width: 800px;
  margin: 0 auto;
}

.footer-title {
  font-size: 1.8rem;
  color: #28527a;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.footer-content p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
  line-height: 1.6;
}

.footer-button-support,
.footer-button-faq {
  background-color: #28527a;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.welcome-message {
  color: white;
}

.footer-button-support:hover,
.footer-button-faq:hover {
  background-color: #3e8f41;
}

.footer-button-support:focus,
.footer-button-faq:focus {
  outline: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .dashboard-overview .dashboard-stats {
    flex-direction: column;
    gap: 15px;
  }

  .stat-card {
    padding: 15px;
  }

  .share-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .breakdown-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .payout-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .copy-button,
  .download-button,
  .view-terms-button,
  .support-button,
  .faq-button {
    width: 100%;
    padding: 15px;
  }
}



















.earnings-breakdown {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.breakdown-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.breakdown-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  transition: transform 0.2s ease-in-out;
}

.breakdown-card:hover {
  transform: scale(1.02);
}

.card-content {
  text-align: right; /* Adjust for RTL languages like Arabic */
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-detail {
  font-size: 14px;
  margin: 5px 0;
}

.note {
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
}

.loading-message {
  font-size: 16px;
  color: #999;
}

.warning-message {
  font-size: 16px;
  color: #c00;
}
