/* General container styles */
.dashboard-container {
    font-family: 'Arial', sans-serif; 
    margin: 20px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.edit-icon {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px;
    font-size: 14px;
    color: #007BFF;
    transition: color 0.3s ease;
}

.edit-icon:hover {
    color: #0056b3; /* A darker blue for hover effect */
}
/* Main container for booking details */
.booking-details {
    margin: 20px 0;
    padding: 15px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Heading style */
.booking-details h2 {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
    border-bottom: 2px solid #007BFF;
    display: inline-block;
    padding-bottom: 5px;
}

/* Each booking detail block */
.booking-detail-item {
    margin-bottom: 15px;
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
}

/* Booking detail hover effect */
.booking-detail-item:hover {
    border-color: #007BFF;
    box-shadow: 0 2px 6px rgba(0, 123, 255, 0.2);
}

/* Paragraph text style */
.booking-detail-item p {
    font-size: 16px;
    color: #555;
    margin: 5px 0;
}

/* Textarea style */
.booking-detail-item textarea {
    width: 100%;
    min-height: 80px;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    color: #333;
    background: #fff;
    resize: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
    transition: border 0.3s ease;
}

/* Textarea focus style */
.booking-detail-item textarea:focus {
    border-color: #007BFF;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.2);
}

/* Placeholder style */
.booking-detail-item textarea::placeholder {
    color: #999;
    font-style: italic;
}

/* Save space between detail items */
.booking-detail-item:not(:last-child) {
    margin-bottom: 15px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .booking-details {
        padding: 10px;
    }

    .booking-details h2 {
        font-size: 16px;
    }

    .booking-detail-item p,
    .booking-detail-item textarea {
        font-size: 14px;
    }
}
.add-new-description-btn,
.delete-description-btn {
    margin-top: 10px;
    padding: 5px 15px;
    font-size: 14px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.add-new-description-btn {
    background-color: #007BFF;
    color: white;
    transition: background-color 0.3s ease;
}

.add-new-description-btn:hover {
    background-color: #0056b3;
}

.delete-description-btn {
    background-color: #FF5733;
    color: white;
    transition: background-color 0.3s ease;
}

.delete-description-btn:hover {
    background-color: #C70039;
}

.editable-textarea {
    width: 100%;
    min-height: 50px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    resize: vertical;
}

.subtitle-top {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    position: relative; /* Allows positioning */
    top: -1px; /* Adjust this value to move it upwards */
    font-size: 14px; /* Optional: Adjust font size */
    font-weight: bold; /* Optional: Make it bold */
    text-align: center; /* Optional: Align the text to the center */
}

.dashboard-container h1 {
    text-align: center;
    color: #003366;
    margin-bottom: 10px;
}

.dashboard-container p {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

/* Buttons */
button {
    background-color: #003366;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
}

button:hover {
    background-color: #0055a5;
}

.filters input[type="text"] {
    padding: 10px;
    width: 250px;
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Table styles */
.services-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.services-table thead {
    background-color: #003366;
    color: #fff;
}

.services-table th,
.services-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ccc;
}

.services-table th {
    font-weight: bold;
}

.services-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.services-table tbody tr:hover {
    background-color: #e0f3ff;
}

/* List styling */
ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
}

textarea {
    width: 100%;
    min-height: 80px;
    resize: vertical;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
}

a {
    color: #0055a5;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Responsive styles */
@media (max-width: 768px) {
    .filters {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .filters input[type="text"] {
        width: 90%;
        margin-top: 10px;
    }

    .services-table th,
    .services-table td {
        font-size: 14px;
        padding: 5px;
    }

    textarea {
        min-height: 60px;
    }
}

.rtl-text {
    direction: rtl !important;
    text-align: right !important;
}





















.hospital-card {
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    z-index: 1000;
}

.location-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.modal-content {
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    max-width: 600px;
    margin: auto;
}

.modal-logo {
    width: 160px;
    height: 160px;
    object-fit: contain;
    margin-top: 10px;
}

.hospital-logo {
    width: 100px;
    margin-inline-end: 10px;
}

.hospital-name {
    font-size: 24px;
    font-weight: bold;
    color: #072C7B;
}

.hospital-subtitle {
    font-size: 14px;
    color: #6c757d;
    margin-top: 5px;
    margin-bottom: 15px;
}

.locofe {
    display: flex;
    align-items: center;
    margin-top: 18px;
}

.hospital-service-add[dir="rtl"] .locofe {
    margin-right: 18px;
    margin-left: 0;
    padding-right: 5px;
}

.hospital-service-add[dir="ltr"] .locofe {
    margin-left: 18px;
    margin-right: 0;
    padding-left: 5px;
}

.location-icon {
    width: 20px;
}

.optionsitem {
    text-align: right !important;
    direction: rtl;
    padding: 5px;
    margin: 5px 0;
    display: flex; 
    align-items: center;
    justify-content: flex-end;
}

.valueAdded {
    background-color: #f4f6fa;
    display: flex;
    align-items: center; 
    height: 40px; 
    padding: 5px; 
    justify-content: flex-start; 
}

.whiteC {
    text-rendering: optimizeLegibility; 
    color: #072c7b; 
    font-size: 20px; 
    font-weight: 700;
    margin: 0;
}

.samerow {
    display: flex; 
    align-items: center;
    justify-content: flex-start; 
    gap: 10px; 
}

.service-options {
    margin-top: 20px;
    display: flex; 
    flex-direction: column;
    align-items: flex-start; 
    text-align: right; 
}

.blueTkhile {
    background-image: linear-gradient(90deg, #007bff 0, #007bff 50%, #007bff); 
    border: none;
    display: flex; 
    justify-content: center;
    align-items: center; 
    border-radius: 0; 
    box-shadow: 3px 3px 8px #0000004d; 
    color: #f4f6fa; 
    cursor: pointer; 
    font-size: 16px; 
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    width: 250px; 
    height: 40px;
    text-decoration: none; 
}

.button-container {
    display: flex;
    justify-content: flex-end; 
    align-items: center; 
    margin: 10px 0;
    .header-buttons-container {
        display: flex;
        justify-content: center; /* Centers the buttons horizontally */
        align-items: center; /* Centers the buttons vertically (if needed) */
        margin: 20px 0; /* Adds space above and below the buttons */
    }
    
    .header-buttons {
        display: flex; /* Ensures the buttons are inline */
        gap: 10px; /* Adds space between the buttons */
    }
    }



    .rejection-message {
        margin-top: 10px;
        padding: 10px;
        border: 1px solid #dc3545;
        background-color: #f8d7da;
        color: #721c24;
        font-size: 0.9em;
        border-radius: 4px;
        text-align: right;
    }
    

    .checked-requests-container {
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    
    .request-count {
        font-size: 1.2em;
        margin-bottom: 20px;
        color: #333;
    }
    
    .request-item {
        margin-bottom: 20px;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    
    .modal-content {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    
  



    
    .rejection-message {
        margin-top: 10px;
        padding: 10px;
        border-left: 4px solid #dc3545;
        background-color: #f8d7da;
        color: #721c24;
        font-size: 0.9em;
        border-radius: 4px;
    }
    
    .no-requests-message {
        font-size: 1.2em;
        text-align: center;
        color: #555;
    }
    .request-count {
        font-size: 1.2em;
        color: #333;
        margin-bottom: 20px;
    }
    
    .request-count span {
        font-weight: bold;
        color: #007bff; /* Highlight specific counts */
    }
    .request-count {
        font-size: 1.2em;
        color: #333;
        margin-bottom: 20px;
        display: flex;
        gap: 10px;
    }
    
    .request-count span {
        font-weight: 500;
    }
    
    .request-count strong {
        color: #007bff; /* Highlighted color for counts */
        font-weight: bold;
    }
    


    








    .editable-input {
        width: 100%;
        padding: 5px;
        margin-top: 5px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
    }
    
    .edit-actions {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 10px;
    }
    
    .save-btn,
    .cancel-btn {
        padding: 5px 10px;
        font-size: 14px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
    
    .save-btn {
        background-color: green;
        color: white;
    }
    
    .cancel-btn {
        background-color: red;
        color: white;
    }
    