/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
  direction: rtl; /* Set text direction to right-to-left */
  text-align: right; /* Align text to the right */
}

.ambassador-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  /* Adjust the Welcome Banner */
  .welcome-banner {
    text-align: center;
    padding: 15px;
  }

  .welcome-banner h1 {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }

  .welcome-message {
    font-size: 1rem;
    color: white;
  }

  /* Adjust Dashboard Overview */
  .dashboard-stats {
    flex-direction: column;
    gap: 15px;
  }

  .stat-card {
    padding: 15px;
    text-align: center;
  }

  /* Adjust Sharing Tools */
  .share-button {
    width: 100%;
    margin-bottom: 10px;
  }

  /* Adjust Breakdown Items */
  .breakdown-item {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  .breakdown-date,
  .breakdown-earnings,
  .breakdown-client {
    text-align: right;
    margin-bottom: 5px;
  }

  /* Adjust Payout Items */
  .payout-item {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  /* Adjust Footer */
  .footer {
    padding: 15px 10px;
  }

  .footer-title {
    font-size: 1.5rem;
  }

  .footer-content p {
    font-size: 1rem;
    line-height: 1.4;
  }

  .footer-button-support,
  .footer-button-faq {
    width: 100%;
    margin-bottom: 10px;
  }
}
