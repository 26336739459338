.pending-approval-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;
  }
  
  .pending-approval-card {
    background: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    text-align: center;
  }
  
  .pending-approval-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .pending-approval-message {
    font-size: 16px;
    color: #555;
    margin-bottom: 15px;
  }
  
  .pending-approval-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
  }
  
  .pending-approval-button,
  .pending-approval-contact-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.3s ease;
  }
  
  .pending-approval-button:hover,
  .pending-approval-contact-button:hover {
    background-color: #0056b3;
  }
  
  .pending-approval-contact-button {
    background-color: #28a745;
  }
  
  .pending-approval-contact-button:hover {
    background-color: #1e7e34;
  }
  