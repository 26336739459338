.provider-management {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #fff9e6; /* Light yellow background */
    color: #4a4a4a; /* Neutral text color for readability */
}

.header-section {
    margin-bottom: 20px;
}

.page-summary {
    margin-bottom: 10px;
    font-size: 1.1em;
    color: #6b6b6b; /* Slightly darker neutral text */
}

.actions {
    margin-bottom: 20px;
}

.actions .primary-btn,
.actions .secondary-btn {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
}

.primary-btn {
    background-color: #ffd700; /* Vibrant golden yellow */
    color: #4a4a4a;
    transition: background-color 0.3s ease;
}

.primary-btn:hover {
    background-color: #e6be00; /* Darker golden yellow on hover */
}

.secondary-btn {
    background-color: #ffed99; /* Pale yellow */
    color: #4a4a4a;
    transition: background-color 0.3s ease;
}

.secondary-btn:hover {
    background-color: #ffe066; /* Medium yellow on hover */
}

.filter-section {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
}

.search-bar {
    padding: 10px;
    width: 300px;
    border: 1px solid #ffcc33; /* Medium yellow border */
    border-radius: 4px;
    background-color: #fffdfa; /* Slightly off-white for contrast */
}

.dropdown {
    padding: 10px;
    border: 1px solid #ffd700; /* Golden yellow border */
    border-radius: 4px;
    background-color: #fffae6; /* Very light yellow */
}

.stats-summary {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    font-size: 1.1em;
    color: #6b6b6b;
}

.stats-summary p {
    background-color: #fff4cc; /* Soft pale yellow */
    padding: 10px 15px;
    border-radius: 8px;
    font-weight: bold;
}

.provider-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.provider-table th,
.provider-table td {
    padding: 10px;
    border: 1px solid #ffd700; /* Golden yellow border */
    text-align: right;
}

.provider-table th {
    background-color: #ffe680; /* Soft golden yellow for headers */
    font-weight: bold;
}

.provider-table td {
    background-color: #fffaf0; /* Very light yellow for rows */
}



.provider-table .status.pending {
    color: #ffcc33; /* Medium yellow for pending */
    font-weight: bold;
}

.action-btn {
    margin-right: 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
}

.action-btn.view {
    background-color: #ffed99; /* Pale yellow */
    color: #4a4a4a;
    transition: background-color 0.3s ease;
}

.action-btn.view:hover {
    background-color: #ffe066; /* Medium yellow on hover */
}

.action-btn.approve {
    background-color: #ffe680; /* Soft golden yellow */
    color: #4a4a4a;
    transition: background-color 0.3s ease;
}

.action-btn.approve:hover {
    background-color: #ffcc00; /* Bright golden yellow on hover */
}

.action-btn.reject {
    background-color: #ffd699; /* Warm yellow for reject */
    color: #4a4a4a;
    transition: background-color 0.3s ease;
}

.action-btn.reject:hover {
    background-color: #e6a600; /* Orange-yellow on hover */
}








/* Common styling for the status */
.status {
    display: inline-block;
    padding: 8px 12px;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
}

/* Styling for approved status */
.status.approved {
    background-color: #28a745; /* Green for approval */
    border: 1px solid #1e7e34;
    box-shadow: 0 2px 4px rgba(40, 167, 69, 0.4);
}

/* Styling for rejected status */
.status.rejected {
    background-color: #dc3545; /* Red for rejection */
    border: 1px solid #b21f2d;
    box-shadow: 0 2px 4px rgba(220, 53, 69, 0.4);
}

/* Add hover effect for better interaction */
.status:hover {
    opacity: 0.9;
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
}
.status.pending {
    background-color: #000000; /* Yellow for Pending */
    color: #ffffff;
    border: 1px solid #000000;
}


/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Content */
.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.modal-content h2 {
    margin-bottom: 20px;
    font-size: 18px;
}

.modal-content p {
    margin-bottom: 15px;
    font-size: 16px;
}

.modal-content .action-btn.close {
    background-color: #dc3545; /* Red for Close */
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.modal-content .action-btn.close:hover {
    background-color: #b21f2d;
}


.add-row-button {
    display: inline-block;
    margin: 20px auto; /* Center-align the button */
    padding: 12px 24px; /* Balanced padding */
    background: linear-gradient(135deg, #f7c844, #ffe680); /* Subtle yellow gradient */
    color: #333; /* Default dark gray font color */
    font-size: 16px; /* Professional and readable font size */
    font-weight: bold; /* Clear and bold text */
    text-align: center;
    border: none;
    border-radius: 8px; /* Slightly rounded corners for a modern look */
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transition for hover effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Light shadow for depth */
    max-width: 300px; /* Restrict width for proportional size */
    width: 100%; /* Responsive width */
    position: relative;
    overflow: hidden;
}

.add-row-button:hover {
    transform: translateY(-2px); /* Subtle lift effect */
    background: linear-gradient(135deg, #ffeb99, #fff7db); /* Lighter hover gradient */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow for hover */
    color: #000 !important; /* Force black font color on hover */
}

.add-row-button:active {
    transform: translateY(0); /* Reset lift on click */
    background: linear-gradient(135deg, #e6be4f, #f2e1a1); /* Slightly darker yellow on click */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Reduced shadow */
    color: #000 !important; /* Keep black text on active state */
}

  
  @keyframes ripple {
    0% {
      transform: translateX(-50%) translateY(100%);
    }
    50% {
      transform: translateX(-50%) translateY(90%);
    }
    100% {
      transform: translateX(-50%) translateY(100%);
    }
  }
  @keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
