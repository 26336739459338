body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f6fa;
  }
  
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .login-box {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 350px;
  }
  
  .login-logo {
    width: 80px;
    margin-bottom: 20px;
  }
  
  .login-title {
    font-size: 20px;
    color: #001f7f;
    margin: 0;
  }
  
  .login-subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .input-container {
    margin-bottom: 15px;
    text-align: right;
  }
  
  .input-label {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
    display: block;
  }
  
  .input-label span {
    font-size: 12px;
    color: #999;
  }
  
  .login-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .login-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  