/* Overall styles for the signup page */
.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f6fa;
  /* Light background color */
}
.spinner {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  border-top: 2px solid #007bff; /* Spinner color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.signup-box {
  background-color: #ffffff;
  /* White background for the form */
  padding: 30px;
  border-radius: 10px;
  /* Rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
  text-align: center;
  width: 400px;
  /* Adjust form width as needed */
}

/* Resize the logo */
.signup-logo {
  width: 60px;
  /* Adjust logo size */
  height: auto;
  /* Maintain aspect ratio */
  margin-bottom: 20px;
  /* Space below the logo */
}

/* Styling the heading */
.signup-box h1 {
  font-size: 24px;
  color: #333333;
  /* Text color */
  margin-bottom: 20px;
}

/* Form styles */
.signup-box form {
  display: flex;
  flex-direction: column;
  /* Stack inputs vertically */
  gap: 15px;
  /* Space between elements */
}

.signup-box input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.signup-box input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Dropdown select styles */
.role-select {
  text-align: left;
  /* Align label and dropdown */
}

.role-select label {
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
}

.role-select select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

/* Button styling */
.signup-box button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.signup-box button:hover {
  background-color: #0056b3;
}

/* Link styling */
.signup-box p {
  font-size: 14px;
}

.signup-box p a {
  color: #007bff;
  text-decoration: none;
}

.signup-box p a:hover {
  text-decoration: underline;
}