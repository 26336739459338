/* General Page Styles */
body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  background-color: #f8f9fa; /* Light gray background */
  margin: 0;
  padding: 0;
}

.top-management-container {
  padding: 20px;
  max-width: 1200px; /* Increased width for larger tables */
  margin: 20px auto;
  background-color: #ffffff; /* White background for contrast */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  border-radius: 12px; /* Smooth rounded corners */
}

.top-management-container h1 {
  font-size: 2.5rem;
  color: #343a40; /* Dark gray text */
  text-align: center;
  margin-bottom: 20px;
}

.top-management-container p {
  font-size: 1rem;
  color: #495057; /* Subtle gray */
}

/* Section Styles */
.top-management-container section {
  margin: 30px 0;
}

.top-management-container h2 {
  font-size: 1.8rem;
  color: #007bff; /* Blue accent for section headers */
  margin-bottom: 15px;
  border-bottom: 3px solid #007bff; /* Accent underline */
  padding-bottom: 8px;
  text-transform: uppercase; /* Make section headers uppercase */
}

/* Styled Table */
.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.styled-table th, .styled-table td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #ddd;
  font-size: 14px;
}

.styled-table th {
  background-color: #007bff; /* Blue header background */
  color: #ffffff; /* White text */
  text-transform: uppercase;
}

.styled-table tbody tr {
  transition: background-color 0.3s ease;
}

.styled-table tbody tr:hover {
  background-color: #f1f3f5; /* Light gray hover effect */
}

.styled-table tbody tr:nth-child(even) {
  background-color: #f9f9f9; /* Alternate row colors */
}

.styled-table td {
  color: #495057; /* Subtle gray text */
}

/* Buttons */
.edit-button, .delete-button {
  padding: 8px 16px;
  margin: 0 5px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.edit-button {
  background-color: #28a745; /* Green for edit */
  color: #ffffff;
}

.edit-button:hover {
  background-color: #218838;
}

.delete-button {
  background-color: #dc3545; /* Red for delete */
  color: #ffffff;
}

.delete-button:hover {
  background-color: #c82333;
}

/* No Data Message */
.no-data {
  text-align: center;
  font-size: 16px;
  font-style: italic;
  color: #6c757d; /* Subtle gray */
}

/* Add/Edit Member Form */
.form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 20px 0;
}

.form-group input {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  transition: border-color 0.2s ease;
}

.form-group input:focus {
  border-color: #007bff; /* Blue border on focus */
  outline: none;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  background-color: #007bff;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .top-management-container {
      padding: 15px;
  }

  .top-management-container h1 {
      font-size: 2rem;
  }

  .top-management-container h2 {
      font-size: 1.5rem;
  }

  .form-group {
      flex-direction: column;
  }

  .styled-table th, .styled-table td {
      font-size: 12px;
      padding: 10px;
  }

  button {
      font-size: 12px;
      padding: 8px 16px;
  }
}
.form-group {
  display: flex;
  flex-wrap: wrap; 
  gap: 15px;
  margin-bottom: 20px;
}

.form-group input,
.form-group select {
  flex: 1 1 45%;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group label {
  flex: 1 1 100%;
  font-size: 0.9rem;
  color: #555;
}

.form-buttons {
  display: flex;
  gap: 10px;
}

.form-buttons button {
  padding: 10px 15px;
  font-size: 1rem;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-buttons button:first-child {
  background-color: #007bff;
}

.form-buttons button:last-child {
  background-color: #dc3545;
}










/* Container */
.top-management-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Navigation Bar */
.navbar {
  display: flex;
  justify-content: space-around;
  background-color: #007bff;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.navbar button {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.navbar button:hover,
.navbar button:focus {
  background-color: rgba(255, 255, 255, 0.2);
  transform: scale(1.05);
  border-radius: 5px;
  outline: none;
}

/* Title */
h1 {
  text-align: center;
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

/* Loading Indicator */
p {
  text-align: center;
  font-size: 18px;
  color: #555;
}

/* Reports Section */
.reports-section {
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.reports-section h2 {
  font-size: 24px;
  color: #007bff;
}

.reports-section p {
  font-size: 16px;
  color: #666;
}

/* Discounts Section */
.settings-section {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.settings-section h2 {
  font-size: 24px;
  color: #28a745;
  margin-bottom: 10px;
}

.settings-section label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #555;
}

.settings-section input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.settings-section button {
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.settings-section button:hover {
  background-color: #218838;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
  }

  .navbar button {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }

  h1 {
    font-size: 24px;
  }

  .reports-section,
  .settings-section {
    padding: 15px;
  }

  .settings-section input {
    font-size: 14px;
  }
}
.form-container {
  display: flex;
  flex-wrap: wrap; /* Wrap items if they don't fit in one row */
  gap: 20px; /* Add spacing between fields */
  justify-content: space-between; /* Space fields evenly */
  align-items: flex-start; /* Align labels and inputs at the top */
}

.form-item {
  flex: 1; /* Allow each field to take equal space */
  min-width: 200px; /* Set a minimum width for each field */
}

label {
  display: flex;
  flex-direction: column; /* Stack label and input vertically */
  font-size: 14px; /* Adjust label font size */
  margin-bottom: 5px; /* Add spacing below the label */
}

input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%; /* Ensure input takes full width of its container */
  box-sizing: border-box;
}
