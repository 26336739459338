.filterINput {
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .filterINput h3 {
    color: #007bff;
    margin-bottom: 10px;
    font-size: 1.2rem;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
  }
  
  .filterINput .filter-group {
    margin-bottom: 15px;
  }
  
  .filterINput .filter-group label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    color: #333;
  }
  
  .filterINput .filter-options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .filterINput .filter-options label {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.95rem;
    background: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  .filterINput .filter-options label:hover {
    background-color: #eef6ff;
    border-color: #007bff;
  }
  
  .filterINput .filter-options input[type="checkbox"] {
    accent-color: #007bff;
    cursor: pointer;
  }
  .specialist {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .specialist h3 {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  table tr {
    border-bottom: 1px solid #e0e0e0;
  }
  
  table td {
    padding: 10px;
    font-size: 16px;
    color: #555;
  }
  
  table label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  table input[type="checkbox"] {
    margin-right: 10px;
    transform: scale(1.2);
    cursor: pointer;
  }
  
  .upload-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .upload-button:hover {
    background-color: #0056b3;
  }

  .hospital-form-add-btn {
    width: auto;
    background-color: #28a745;
    color: white;
    padding: 8px 15px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .hospital-form-add-btn:hover {
    background-color: #218838;
  }
  
  .hospital-form-input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .hospital-form-input-group label {
    margin-right: 10px;
    font-size: 0.9rem;
  }
  
  .hospital-form-input-group input {
    flex: 1;
    margin-left: 10px;
  }
  
  button.add-btn {
    background-color: #6c757d;
    color: white;
    padding: 8px 15px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
  }
  
  button.add-btn:hover {
    background-color: #5a6268;
  }
  
  @media (max-width: 768px) {
    .hospital-form-container {
      padding: 15px;
      margin: 20px auto;
    }
  
    h1 {
      font-size: 1.5rem;
    }
  
    .hospital-form-input-group {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .hospital-form-input-group label,
    .hospital-form-input-group input {
      width: 100%;
      margin: 5px 0;
    }
  
    button.add-btn,
    .hospital-form-add-btn {
      width: 100%;
      padding: 10px;
      text-align: center;
    }
  }  


  .catalog-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
  }
  .upload-button {
    background-color: #007bff; /* Primary blue color */
    color: white; /* White text */
    border: none; /* Remove border */
    padding: 10px 20px; /* Add padding for better spacing */
    font-size: 16px; /* Set font size */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  }
  
  .upload-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
  }
  
  .upload-button:active {
    background-color: #004085; /* Even darker blue on click */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduce shadow on click */
    transform: translateY(2px); /* Simulate button press */
  }
  