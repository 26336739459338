.success-row {
    background-color: #d4edda;
    /* Light green background */
    color: #155724;
    /* Dark green text */
}
.fraud-row {
  background-color: red; /* Red background for rows flagged as fraud */
  color: white; /* Optional: Change text color to white for contrast */
}

.reference-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
}

.reference-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}











.order-details-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
}

.order-details-table th,
.order-details-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.order-details-table th {
    background-color: #007bff;
    color: white;
}

.order-details-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.order-details-table a {
    color: #007bff;
    text-decoration: none;
}

.order-details-table a:hover {
    text-decoration: underline;
}

.order-details-container {
    margin: 20px 0;
}

.order-date-group {
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.order-date-label {
    background-color: #f5f5f5;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.order-details-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.order-details-table th,
.order-details-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.order-details-table th {
    background-color: #f5f5f5;
    font-weight: bold;
}

/* Navbar Container */
.tab-nav {
    display: flex;
    justify-content: center;
    background-color: #f8f9fa;
    border-bottom: 2px solid #dee2e6;
    margin-bottom: 20px;
    padding: 10px 0;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  /* Tab List */
  .tab-list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 20px;
  }
  
  /* Tab Item */
  .tab-item {
    font-size: 16px;
    font-weight: 500;
    color: #6c757d;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  /* Hover Effect */
  .tab-item:hover {
    background-color: #e9ecef;
    color: #495057;
  }
  
  /* Active Tab */
  .active-tab {
    background-color: #007bff;
    color: #fff;
    font-weight: 600;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .tab-nav {
      flex-direction: column;
      align-items: center;
    }
  
    .tab-list {
      flex-direction: column;
      gap: 10px;
    }
  
    .tab-item {
      width: 100%;
      text-align: center;
    }
  }
  .payment-tab {
    padding: 20px;
  }
  
  .payment-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  
  .payment-form label {
    font-weight: bold;
  }
  
  .payment-form input {
    padding: 8px;
    font-size: 16px;
  }
  
  .payment-form button {
    padding: 10px 15px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .payment-form button:hover {
    background-color: #0056b3;
  }
  
  .payment-link {
    margin-top: 20px;
  }
  
  .payment-link a {
    color: #007bff;
    text-decoration: none;
  }
  
  .payment-link a:hover {
    text-decoration: underline;
  }

  










  /* Main Container */
.speed-payment-container {
    background-color: #f8f9fa;
    border-radius: 8px;
    margin: 20px auto;
    padding: 30px;
    max-width: 600px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  /* Content Wrapper */
  .speed-payment-content {
    display: flex;
    flex-direction: column;
  }
  
  /* Title */
  .speed-payment-title {
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Form */
  .speed-payment-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Labels */
  .speed-payment-label {
    font-weight: bold;
    color: #555;
  }
  
  /* Input Fields */
  .speed-payment-input,
  .payment-link-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    width: 100%;
  }
  
  .speed-payment-input:focus,
  .payment-link-input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* Buttons */
  .speed-payment-button,
  .copy-link-button {
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Generate Button */
  .speed-payment-button {
    background-color: #007bff;
  }
  
  .speed-payment-button:hover {
    background-color: #0056b3;
  }
  
  .speed-payment-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Copy Button */
  .copy-link-button {
    background-color: #28a745;
  }
  
  .copy-link-button:hover {
    background-color: #218838;
  }
  
  /* Payment Link Section */
  .payment-link-container {
    margin-top: 20px;
  }
  
  .payment-link-label {
    font-size: 1rem;
    font-weight: bold;
    color: #444;
    margin-bottom: 5px;
  }
  