/* General Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.navbar-container {
  display: flex; /* Align items horizontally */
  justify-content: space-between; /* Spread items (logo and links) */
  align-items: center; /* Center items vertically */
  padding: 0 20px; /* Add horizontal padding */
  height: 70px; /* Set a consistent height */
  background-color: #1a1a1a; /* Dark background for a professional look */
  color: white; /* Text color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  z-index: 10; /* Ensure navbar stays on top */
}

/* Logo Styling */
.navbar-logo a {
  font-size: 24px;
  font-weight: bold;
  color: #007bff; /* Blue branding color */
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-logo a:hover {
  color: white; /* Invert color on hover */
}

/* Desktop Navbar Links */
.navbar-links {
  display: none; /* Hidden by default (for mobile view) */
}

@media (min-width: 768px) {
  .navbar-links {
    display: flex; /* Flex container for links */
    gap: 20px; /* Space between links */
  }

  .navbar-links li {
    list-style: none; /* Remove bullet points */
  }

  .navbar-links li a {
    text-decoration: none;
    color: white; /* Default link color */
    font-size: 16px; /* Adjust font size */
    padding: 8px 16px; /* Add padding for better click area */
    border-radius: 4px; /* Rounded corners */
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .navbar-links li a:hover {
    background-color: #007bff; /* Highlight on hover */
    color: #1a1a1a; /* Dark text on hover */
  }

  /* Logout Button Styling */
  .logout-button {
    background-color: transparent;
    color: white;
    border: 2px solid #007bff;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .logout-button:hover {
    background-color: #007bff;
    color: #1a1a1a; /* Dark text on hover */
  }
}

/* Centering the Navbar in Desktop View */
.navbar-container {
  margin: 0 auto; /* Center horizontally */
}

/* Mobile Menu Button */
.mobile-toggle {
  display: block; /* Visible by default */
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: #007bff; /* Branding color */
  color: white; /* Light text */
  padding: 10px 12px; /* Add padding for better click area */
  border-radius: 50%; /* Circular button */
  cursor: pointer; /* Pointer cursor on hover */
  z-index: 20; /* Ensure above other elements */
  transition: transform 0.3s ease;
}

.mobile-toggle:hover {
  transform: scale(1.1); /* Slight zoom effect */
}

/* Hide Mobile Menu Button on Desktop */
@media (min-width: 768px) {
  .mobile-toggle {
    display: none;
  }
}

/* Mobile Sidebar */
.fixed {
  position: fixed;
  top: 0;
  left: 0; /* Sidebar slides in from the left */
  width: 250px; /* Sidebar width */
  height: 100%; /* Full height */
  background-color: #1a1a1a; /* Match navbar background */
  color: white;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  z-index: 100; /* Ensure it's above everything */
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between items */
  transition: transform 0.3s ease;
}

/* Sidebar Links */
.fixed ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.fixed ul li {
  margin-bottom: 20px;
}

.fixed ul li a {
  text-decoration: none;
  color: white;
  font-size: 18px;
  transition: color 0.3s ease;
}

.fixed ul li a:hover {
  color: #007bff; /* Highlight link on hover */
}

/* Close Button Styling */
.fixed .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  color: white;
  font-size: 24px;
  cursor: pointer;
  border: none;
  transition: color 0.3s ease;
}

.fixed .close-button:hover {
  color: #007bff; /* Highlight on hover */
}

/* Overlay for Sidebar */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 99; /* Below the sidebar but above the page */
}
